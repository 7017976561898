import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { BackButton, SubmissionPage } from '../../CertificateForms/CertificateFormComponents';
import ProductDataService from "../../../services/ProductService";
import moment from "moment-timezone";
import { Routes } from "../../../../routes";
import { IsReadOnlyPermissions } from '../../../../auth/PermissionCheck';
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Formik, Form, FieldArray, useFormikContext, getIn } from 'formik';
import FormController from "../../common/FormController"
import ZoneService from "../../../services/ZoneService";
import ProductMeatPreparationService from "../../../services/ProductMeatPreparationService";
import ProductScientificSpeciesDataService from "../../../services/ProductScientificSpeciesService";
import TemperatureService from "../../../services/TemperatureService";
import ProductService from "../../../services/ProductService";
import { MultiSelectField } from "../../common/MultiSelect";
import * as MeatPreparation8383Labels from "./MeatPreparation8383Labels"
import SpeciesTable from "../Components/SpeciesTable";
import FormSubmission from "../../common/FormSubmission";
import { Col, Row, Button, Card, Alert } from "@themesberg/react-bootstrap";
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faLeaf, faTimes } from '@fortawesome/free-solid-svg-icons';
import MeatBseRisks from '../Meat/Components/MeatBseRisks';
import { GetCertificateFormVersion } from '../../CertificateForms/CertificateFormFunctions';
import ColdStoreSelect from '../../../../components/EstablishmentSelect/ColdStoreSelect';
import SlaughterhouseSelect from '../../../../components/EstablishmentSelect/SlaughterhouseSelect';
import SiteService from '../../../services/SiteService';
import EstablishmentsService from '../../../services/EstablishmentsService';
import ActivityService from '../../../services/ActivityService';
import SiteSelect from '../../../../components/EstablishmentSelect/SiteSelect';
// import myData from '../../../../data/data.json';
// import {FilterZones} from '../Meat//Components/ZonesFilter2';


const MeatPreparation8383AddPage = (props) => {
    const certificateNumber = 8383;
    const [isSubmitting, setSubmitting] = useState(false);
    const { id, attestationId } = useParams();
    const [formVersion] = useState(GetCertificateFormVersion(certificateNumber));
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [isGEFS, setIsGEFS] = useState(true);
    const [errors, setErrors] = useState(null);
    const [productDetail, setProductDetail] = useState([]);
    const [editing, setEditing] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [ready, setReady] = useState(false);
    const [product, SetProduct] = useState([]);
    const [speciesNotGame, SetSpeciesNotGame] = useState(false);
    const [speciesIsGame, SetSpeciesIsGame] = useState(false);
    const [speciesIsUngulates, SetSpeciesIsUngulates] = useState(false);
    const [speciesNotUngulates, SetSpeciesNotUngulates] = useState(false);
    const [RUF, SetRUF] = useState(false);
    const [storageRequired, SetStorageRequired] = useState(false);
    const [productId, setProductId] = useState(props.match.params.id);
    const [specieslist, SetSpecies] = useState([]);
    const [AnimalHealth, SetAnimalHealth] = useState(false);

    let title = (editing == true ? "Edit " : "New") + " Meat Preparation (#8383) Information"

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
        item3: { name: 'Meat Preparation 8383', link: Routes.MeatPreparation8383AddPage.path },
    };
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [memberStates, SetMemberStates] = useState([]);
    const [controlledRisk, SetControlledRisk] = useState([]);
    const [negligbleRisk, SetNegligbleRisk] = useState([]);
    const [apiValues, SetApiValues] = useState([]);
    const [siteEstablishment, setSiteEstablishment] = useState([]);
    const [coldStoreEstab, setColdStoreEstab] = useState([]);
    const [manufacturingPlantEstab, setManufacturingPlantEstab] = useState([]);

    const initialState = [
        { zoneGroupName: 'ALL_FRESH_MEAT_UNGULATES_BOV', speciesGroupName: 'BOV', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_UNGULATES_OVI', speciesGroupName: 'OVI', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_UNGULATES_POR', speciesGroupName: 'POR', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_UNGULATES_RUF', speciesGroupName: 'RUF', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_UNGULATES_RUW', speciesGroupName: 'RUW', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_UNGULATES_SUF', speciesGroupName: 'SUF', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_UNGULATES_SUW', speciesGroupName: 'SUW', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_POULTRY_GAME_GB', speciesGroupName: 'GB', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_POULTRY_GAME_POU', speciesGroupName: 'POU', treatmentType: 'A', zonesArr: [] },
        { zoneGroupName: 'ALL_FRESH_MEAT_POULTRY_GAME_RAT', speciesGroupName: 'RAT', treatmentType: 'A', zonesArr: [] },];

    const [groupingsArr, SetgroupingsArr] = useState(initialState);

    const [arrLoaded, SetarrLoaded] = useState([]);


    const initialValues = {
        productId: props.match.params.id,
        coldStore: apiValues?.coldStore ?? '',
        coldStoreNameAddress: apiValues?.coldStoreNameAddress ?? '',
        coldStoreEstablishments: apiValues?.coldStoreEstablishments ?? [],
        identificationMark: apiValues?.identificationMark ?? '',
        identificationMarkEstablishment: apiValues?.identificationMarkEstablishment ?? siteEstablishment[0],
        abattoirEstablishments: apiValues?.abattoirEstablishments ?? [],
        manufacturingPlantEstablishments: apiValues?.manufacturingPlantEstablishments ?? [],
        abattoir: apiValues?.abattoir ?? '',
        health_1_2_1: apiValues?.health_1_2_1 ?? false,
        health_1_2_2: apiValues?.health_1_2_2 ?? false,

        health_1_9_1: apiValues?.health_1_9_1 ?? false,
        health_1_9_1_statements: apiValues?.health_1_9_1_statements ?? [],

        health_1_3_2_1: apiValues?.health_1_3_2_1 ?? false,
        health_1_3_2_1_statements: apiValues?.health_1_3_2_1_statements ?? 0,

        health_1_11: apiValues?.health_1_11 ?? false,

        health_1_12: apiValues?.health_1_12 ?? false,
        health_1_12_statements: apiValues?.health_1_12_statements ?? [],

        health_1_13: apiValues?.health_1_13 ?? false,
        health_1_14: apiValues?.health_1_14 ?? false,

        animal_2_1_A: apiValues?.animal_2_1_A ?? false,
        animal_2_1_B: apiValues?.animal_2_1_B ?? false,
        animal_2_1_C: apiValues?.animal_2_1_C ?? false,

        animal_2_1_1: apiValues?.animal_2_1_1 ?? false,
        animal_2_1_2: apiValues?.animal_2_1_2 ?? false,
        animal_2_1_zone: apiValues?.animal_2_1_zone ?? [],
        animal_2_1_B_zone: apiValues?.animal_2_1_B_zone ?? [],

        BseControlled: apiValues?.bseControlled ?? 0,
        BseRiskCategoryNegligible: apiValues?.bseRiskCategoryNegligible ?? false,
        BseRiskCategoryControlled: apiValues?.bseRiskCategoryControlled ?? false,
        BseRiskCategoryUndetermined: apiValues?.bseRiskCategoryUndetermined ?? false,
        BseRiskCategoryNegligibleStatements: apiValues?.bseRiskCategoryNegligibleStatements ?? null,
        BseRiskCategoryControlledStatements: apiValues?.bseRiskCategoryControlledStatements ?? null,
        storageRequired: false,
        storageDates: apiValues?.storageDates ?? [],
        zones1box: null,
        productScientificSpecies: apiValues?.productScientificSpecies ?? [],
        meatData:
        {
            speciesList: apiValues?.speciesList ?? [],
            containsBOV: false,
            containsOVI: false,
            containsPOR: false,
            containsPOU: false,
            containsRAT: false,
            containsRUF: false,
            containsRUW: false,
            containsSUF: false,
            containsSUW: false,
            containsGB: false,
        },
    }

    const meatGroups = [
        { name: 'BOV' },
        { name: 'OVI' },
        { name: 'POR' },
        { name: 'POU' },
        { name: 'RAT' },
        { name: 'RUF' },
        { name: 'RUW' },
        { name: 'SUF' },
        { name: 'SUW' },
        { name: 'GB' },
    ]

    const validationMsgRequired = "This field is required";
    const validationMsgAtLeastOne = "Select at least one of these options";

    //Validation
    const validationSchema = Yup.object().shape({
        identificationMarkEstablishment: Yup.object().nullable().required(validationMsgRequired),
        // coldStoreEstablishments: Yup.array().min(1, validationMsgAtLeastOne)
        //     .required(validationMsgRequired),
        abattoirEstablishments: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        productScientificSpecies: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        BseRiskCategoryNegligible: Yup.bool()
            .test('BseRiskCategoryNegligible', validationMsgAtLeastOne, function (x) {
                return (this.parent.containsBOV || this.parent.containsOVI || this.parent.containsRUW) ? (this.parent.BseRiskCategoryNegligible || this.parent.BseRiskCategoryControlled || this.parent.BseRiskCategoryUndetermined) : true;
            }),
        BseRiskCategoryControlled: Yup.bool()
            .test('BseRiskCategoryControlled', validationMsgAtLeastOne, function (x) {
                return (this.parent.containsBOV || this.parent.containsOVI || this.parent.containsRUW) ? (this.parent.BseRiskCategoryNegligible || this.parent.BseRiskCategoryControlled || this.parent.BseRiskCategoryUndetermined) : true;
            }),
        BseRiskCategoryUndetermined: Yup.bool()
            .test('BseRiskCategoryUndetermined', validationMsgAtLeastOne, function (x) {
                return (this.parent.containsBOV || this.parent.containsOVI || this.parent.containsRUW) ? (this.parent.BseRiskCategoryNegligible || this.parent.BseRiskCategoryControlled || this.parent.BseRiskCategoryUndetermined) : true;
            }),
        BseRiskCategoryNegligibleStatements: Yup.array().nullable()
            .when("BseRiskCategoryNegligible", {
                is: true,
                then: Yup.array().min(1, validationMsgAtLeastOne)
            }),
        BseRiskCategoryControlledStatements: Yup.array().nullable()
            .when("BseRiskCategoryControlled", {
                is: true,
                then: Yup.array().min(1, validationMsgAtLeastOne)
            }),
        health_1_9_1_statements: Yup.array()
            .when("health_1_9_1", {
                is: true,
                then: Yup.array()
                    .min(1, validationMsgAtLeastOne)
                    .required(validationMsgRequired)
            }),
        health_1_3_2_1_statements: Yup.number().nullable()
            .when("health_1_3_2_1", {
                is: true,
                then: Yup.number().min(1, validationMsgAtLeastOne)
            }),
        health_1_2_1: Yup.bool()
            .test('health_1_2_1', validationMsgRequired, function (x) {
                return (speciesNotGame === true && x === false)
                    ? false : true;
            }),
        health_1_2_2: Yup.bool()
            .test('health_1_2_2', validationMsgRequired, function (x) {
                return (speciesIsGame === true && x === false)
                    ? false : true;
            }),

        animal_2_1_A: Yup.bool()
            .test('animal_2_1_A', validationMsgAtLeastOne, function (x) {
                return (!this.parent.animal_2_1_A && !this.parent.animal_2_1_B && !this.parent.animal_2_1_C)
                    ? false : true;
            }),
        animal_2_1_B: Yup.bool()
            .test('animal_2_1_B', validationMsgAtLeastOne, function (x) {
                return (!this.parent.animal_2_1_A && !this.parent.animal_2_1_B && !this.parent.animal_2_1_C)
                    ? false : true;
            }),
        animal_2_1_C: Yup.bool()
            .test('animal_2_1_C', validationMsgAtLeastOne, function (x) {
                return (!this.parent.animal_2_1_A && !this.parent.animal_2_1_B && !this.parent.animal_2_1_C)
                    ? false : true;
            }),
        animal_2_1_zone: Yup.array().min(1, validationMsgAtLeastOne)
            .required(validationMsgRequired),
        animal_2_1_1: Yup.bool()
            .test('animal_2_1_1', validationMsgRequired, function (x) {
                return (speciesIsUngulates === true && x === false && this.parent.animal_2_1_B)
                    ? false : true;
            }),
        animal_2_1_2: Yup.bool()
            .test('animal_2_1_2', validationMsgRequired, function (x) {
                return (speciesNotUngulates === true && x === false && this.parent.animal_2_1_B)
                    ? false : true;
            }),
        animal_2_1_B_zone: Yup.array()
            .when("animal_2_1_B", {
                is: true,
                then: Yup.array().min(1, validationMsgAtLeastOne)
                    .required(validationMsgRequired),
            }),
        speciesList: Yup.array().nullable().of(
            Yup.object().shape({
                speciesGroupName: Yup.string()
                    .required(validationMsgRequired)
            }),
            Yup.object().shape({
                speciesData: Yup.string()
                    .required(validationMsgRequired)
            })
        ).min(1, validationMsgAtLeastOne),
        storageDates: Yup.array().nullable()
            .when("storageRequired", {
                is: true,
                then:
                    Yup.array().of(Yup.object().shape({
                        storageDate: Yup.string().nullable().required(validationMsgRequired)
                    })
                    ).min(1, validationMsgAtLeastOne)
            })
    });


    function onSubmit(values, { resetForm }) {
        setSubmitting(true);
        setTimeout(() => {

            let submitData = values;
            submitData.version = formVersion
            submitData.speciesList = values.meatData.speciesList;
            submitData.containsBOV = values.meatData.containsBOV;
            submitData.containsOVI = values.meatData.containsOVI;
            submitData.containsPOR = values.meatData.containsPOR;
            submitData.containsPOU = values.meatData.containsPOU;
            submitData.containsRAT = values.meatData.containsRAT;
            submitData.containsRUF = values.meatData.containsRUF;
            submitData.containsRUW = values.meatData.containsRUW;
            submitData.containsSUF = values.meatData.containsSUF;
            submitData.containsSUW = values.meatData.containsSUW;
            submitData.containsGB = values.meatData.containsGB;




            if (apiValues.length === 0) {
                ProductMeatPreparationService.create(submitData)
                    .then(async response => {
                        await ActivityService.logCertForm(
                            'Meat Preparation 8383',
                            8383,
                            'Create',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                        console.log(e);
                    });
            }
            else {
                ProductMeatPreparationService.update(productId, values)
                    .then(async response => {
                        await ActivityService.logCertForm(
                            'Meat Preparation 8383',
                            8383,
                            'Update',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({})
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                        console.log(e);
                    });
            }
            setFormSubmitted(true);

        }, 1000);
    }


    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create]));
    }, []);

    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);

    function FilterZones(speciesGroupName, treatmentType) {
        console.log("API");
        const speciesGroupNameFound = groupingsArr.find(x => x.speciesGroupName === speciesGroupName && x.treatmentType === treatmentType);
        if (!arrLoaded.find(f => f.speciesGroupName === speciesGroupNameFound.speciesGroupName && f.treatmentType === speciesGroupNameFound.treatmentType)) {
            console.log(speciesGroupName + " " + treatmentType);
            if (speciesGroupNameFound != undefined && speciesGroupNameFound != null && treatmentType != null && treatmentType != undefined) {
                try {
                    ZoneService
                        .getAllByGroup(speciesGroupNameFound.zoneGroupName)
                        .then(response => {

                            if (ready) {
                                var options = response.data.map(d => ({
                                    "id": d.id,
                                    "displayName": d.displayName,
                                    "countryName": d.countryName,
                                    "isoCode": d.isoCode,
                                }))
                                var data = null;
                                data = memberStates.concat(options);
                                data.sort((a, b) => a.displayName.localeCompare(b.displayName));
                                SetgroupingsArr(prevState => {
                                    const newState = prevState.map(x => {
                                        if (x.speciesGroupName === speciesGroupName && x.treatmentType === treatmentType) {
                                            return { ...x, zonesArr: data }
                                        }
                                        return x;

                                    })
                                    return newState;
                                });
                            }
                        }, [])
                        .catch(e => {
                            console.log(e);
                        })
                } catch (e) {

                    console.error(e);
                }
            }
        }
    }



    const ChangeFormValues = () => {
        const { values, setFieldValue } = useFormikContext();
        useEffect(() => {
            values.meatData.speciesList.forEach((speciesGroup) => {
                var groupName = speciesGroup.speciesGroupName;
                setFieldValue(`meatData.contains${groupName}`, true);
            });
            setFieldValue('storageRequired', storageRequired);
            if (ready) {
                SetAnimalHealth(!(values?.productScientificSpecies?.some(species => species.equ)));
                // if (values.productScientificSpecies.length > 0) {
                //     const keys = [
                //         'bov', 'ovi', 'por', 'ruf', 'ruw', 'suf', 'suw', 'pou', 'rat', 'gb'
                //     ];
                //     keys.forEach(key => {
                //         const hasKey = values.productScientificSpecies.some(obj => obj[key] === true);
                //         setFieldValue(`meatData.contains${key.toUpperCase()}`, hasKey);
                //     });
                // }
                if (values.meatData != null) {
                    // const keys = [
                    //     'bov', 'ovi', 'por', 'ruf', 'ruw', 'suf', 'suw', 'pou', 'rat', 'gb'
                    // ];
                    // keys?.forEach(key => {
                    //     const containsKey = `contains${key.toUpperCase()}`;
                    //     if (values.productScientificSpecies?.length > 0) {
                    //         if (!getIn(values.meatData, containsKey) && values.productScientificSpecies.some(obj => obj[key] === true)) {
                    //             setFieldValue(`meatData.contains${key.toUpperCase()}`, true);
                    //             let single_sData = {
                    //                 "productId": productId,
                    //                 "speciesGroupName": key.toUpperCase()
                    //             }
                    //             // values.meatData.speciesList.push(single_sData);
                    //         }
                    //     } else if (getIn(values.meatData, containsKey) && values.meatData.speciesList?.some(obj => obj[key] === true)) {
                    //         values.meatData.speciesList = values.meatData.speciesList?.filter(item => item.speciesGroupName !== key);
                    //         setFieldValue(`meatData.contains${key.toUpperCase()}`, false);
                    //     }
                    // });
                    if (values.meatData.containsBOV || values.meatData.containsOVI || values.meatData.containsPOR || values.meatData.containsRUF || values.meatData.containsSUF || values.meatData.containsPOU || values.meatData.containsGB || values.meatData.containsRAT) {
                        SetSpeciesNotGame(true);
                    }
                    else {
                        setFieldValue('health_1_2_1', false);
                        SetSpeciesNotGame(false);
                    }
                    if (values.meatData.containsRUW || values.meatData.containsSUW) {
                        SetSpeciesIsGame(true);
                    }
                    else {
                        setFieldValue('health_1_2_2', false);
                        SetSpeciesIsGame(false);
                    }
                    if (values.meatData.containsPOU || values.meatData.containsGB || values.meatData.containsRAT) {
                        SetSpeciesNotUngulates(true);
                    }
                    else {
                        SetSpeciesNotUngulates(false);
                        setFieldValue('animal_2_1_2', false);
                    }
                    if (!values?.meatData.containsPOR) {
                        values.health_1_3_1_1 = false;
                        values.health_1_3_1_1_statements = [];
                    }
                    if (values?.health_1_3_1_1 != true) {
                        values.health_1_3_1_1_statements = [];
                    }
                    if (values?.health_1_3_2_1 != true) {
                        values.health_1_3_2_1_statements = 0;
                    }
                    if (values?.health_1_12 != true) {
                        values.health_1_12_statements = [];
                    }

                    if (values?.animal_2_1_B != true) {
                        values.animal_2_1_B_zone = [];
                        setFieldValue('animal_2_1_1', false);
                        setFieldValue('animal_2_1_2', false);
                    }



                    if (!values.meatData.containsBOV && !values.meatData.containsOVI && !values.meatData.containsRUW) {
                        setFieldValue('BseRiskCategoryNegligible', false);
                        setFieldValue('BseRiskCategoryControlled', false);
                        setFieldValue('BseRiskCategoryUndetermined', false);
                    }
                    if (!values?.BseRiskCategoryNegligible) {
                        setFieldValue('BseRiskCategoryNegligibleStatements', null);
                    }
                    if (!values?.BseRiskCategoryControlled) {
                        setFieldValue('BseRiskCategoryControlledStatements', null);
                    }



                    var selected = [];
                    var selectedBSE = [];
                    var selectedMemberState = [];
                    var selectednegligbleRisk = [];
                    var selectedcontrolledRisk = [];
                    var selectedUndetermined = [];
                    var arrCountrys = [];
                    var distinct = null;
                    values.meatData.speciesList.forEach((speciesGroup) => {

                        speciesGroup.speciesData.forEach((sData) => {
                            if (sData != undefined && sData != null) {
                                let single_sData = {
                                    "treatmentType": "",
                                    "speciesGroupName": "",
                                    "zones": []
                                }

                                var speciesGroupName = speciesGroup.speciesGroupName;
                                var zones = sData.zones;
                                single_sData.speciesGroupName = speciesGroupName;
                                single_sData.treatmentType = "A";
                                single_sData.zones = zones;

                                arrCountrys.push(single_sData);
                                sData.countryOfOrigin.forEach((country) => {
                                    if (country != undefined && country != null) {
                                        if (speciesGroupName === "BOV" || speciesGroupName === "OVI" || speciesGroupName === "RUW") {
                                            selectedBSE.push(country);

                                        }
                                        else {
                                            selected.push(country);

                                        }
                                    }
                                });
                            }
                        });
                    });


                    arrCountrys.forEach((arr) => {
                        if (arr != undefined && arr != null && arr != []) {
                            var speciesGroupName = arr.speciesGroupName;
                            var treatmentType = arr.treatmentType;
                            if (!arrLoaded.find(f => f.speciesGroupName === arr.speciesGroupName && f.treatmentType === arr.treatmentType)) {
                                FilterZones(speciesGroupName, treatmentType);
                            }
                            if (arr.treatmentType != null && arr.zones != null && arr.zones != [] && arr.zones.length > 0) {
                                arrLoaded.push(arr);
                                SetarrLoaded(arrLoaded);
                            }
                        }
                    });
                    selectedBSE.forEach((country) => {
                        if (country != undefined && country != null) {
                            if (negligbleRisk.find(f => f.countryName === country.countryName)) {
                                selectednegligbleRisk.push(country);
                            }
                            if (controlledRisk.find(f => f.countryName === country.countryName)) {
                                selectedcontrolledRisk.push(country);
                            }
                            if (!negligbleRisk.find(f => f.countryName === country.countryName) && !controlledRisk.find(f => f.countryName === country.countryName)) {
                                selectedUndetermined.push(country);
                            }
                            const search = (text) => memberStates.filter(({ countryName }) => countryName.includes(text));
                            const result = search(country.countryName);
                            if (result.find(f => f.countryName === country.countryName)) {
                                selectedMemberState.push(country);
                            }
                        }
                    });
                    selected.forEach((country) => {
                        if (country != undefined && country != null) {
                            const search = (text) => memberStates.filter(({ countryName }) => countryName.includes(text));
                            const result = search(country.countryName);
                            if (result.find(f => f.countryName === country.countryName)) {
                                selectedMemberState.push(country);
                            }
                        }
                    });
                    values.animal_2_1_zone.forEach((entry) => {
                        if (memberStates.find(f => f.countryName === entry.countryName)) {
                            selectedMemberState.push(entry);
                        }
                    });

                    values.animal_2_1_B_zone.forEach((entry) => {
                        if (memberStates.find(f => f.countryName === entry.countryName)) {
                            selectedMemberState.push(entry);
                        }
                    });
                    if (selectedMemberState.length > 0) {
                        setFieldValue('animal_2_1_C', true);
                    }
                    else {
                        setFieldValue('animal_2_1_C', false);
                    }
                    if (selectednegligbleRisk.length > 0) {
                        setFieldValue('BseRiskCategoryNegligible', true);
                    }
                    else {
                        setFieldValue('BseRiskCategoryNegligible', false);
                    }
                    if (selectedcontrolledRisk.length > 0) {
                        setFieldValue('BseRiskCategoryControlled', true);
                    }
                    else {
                        setFieldValue('BseRiskCategoryControlled', false);
                    }
                    if (selectedUndetermined.length > 0) {
                        setFieldValue('BseRiskCategoryUndetermined', true);
                    }
                    else {
                        setFieldValue('BseRiskCategoryUndetermined', false);
                    }




                    if (values?.meatData.containsRUF) {
                        var speciesListLength = values.meatData.speciesList?.length;
                        for (let i = 0; i < speciesListLength; i++) {
                            var speciesDataLength = values.meatData.speciesList?.[i].speciesData?.length;
                            for (let j = 0; j < speciesDataLength; j++) {
                                if (values.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('CA-0') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('CA-1') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('CA-2') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('CA-3') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('CA-4') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('CA-5') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('CA-6') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('CA-7') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('CA-8') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('CA-9') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('CA-10') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('CA-11') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('CA-12') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('US-0') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('US-1') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('US-2') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('US-3') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('US-4') ||
                                    values.meatData.speciesList?.[i].speciesData?.[j].countryOfOrigin?.map(x => x.isoCode).includes('US-5')
                                )
                                    SetRUF(true)
                            }
                        }
                    }
                    else {
                        SetRUF(false)
                    }


                }

                var result = [...new Set([].concat(...groupingsArr.map((o) => o.zonesArr)))]
                if (Array.isArray(result) && !result.length == 0) {
                    distinct = Array.from(new Set(result.map(JSON.stringify))).map(JSON.parse);
                    distinct.sort((a, b) => a.countryName.localeCompare(b.countryName));
                    if (Array.isArray(distinct) && !distinct.length == 0) {
                        let animal_2_1_2_1_B_Combined = values.animal_2_1_zone.concat(values.animal_2_1_B_zone);
                        if (Array.isArray(distinct) && animal_2_1_2_1_B_Combined != null) {
                            var newdistinct = distinct.filter(({ id: id1 }) => !animal_2_1_2_1_B_Combined.some(({ id: id2 }) => id2 === id1));
                            values.zones1box = newdistinct;
                        }

                    }
                }
                setColdStoreEstab(values?.coldStoreEstablishments);
                setManufacturingPlantEstab(values?.manufacturingPlantEstablishments);
            }
        }, [values]);
        return null;
    };



    useEffect(() => {
        async function fetchListData() {
            if (!ready) {
                try {
                    const populateProductDetailData = new Promise((resolve, reject) => {
                        resolve(
                            ProductDataService.getProductDetailByProductId(productId)
                                .then(response => {
                                    var res = response.data;
                                    console.log(res);
                                    setProductDetail(res);
                                    //Get Temperature Name
                                    if (res.temperatureId != null) {
                                        TemperatureService.getById(res.temperatureId)
                                            .then(response => {
                                                var res = response.data;
                                                if (res.temperatureName.includes("Frozen")) {
                                                    SetStorageRequired(true);
                                                }
                                                else {
                                                    SetStorageRequired(false);
                                                }
                                            })
                                            .catch(e => {
                                                console.log(e);
                                            });
                                    }
                                    //Get site estab
                                    //Get site operatorId
                                    SiteService.getById(response.data.siteId)
                                        .then(response2 => {

                                            //Get establishment data from operatorid
                                            EstablishmentsService.getEstablishmentsDetails([response2.data.tracesId])
                                                .then(response3 => {
                                                    //set parent site for this product
                                                    setSiteEstablishment(response3.data)
                                                }, [])
                                                .catch(e => {
                                                    console.log(e);
                                                });
                                        }, [])
                                        .catch(e => {
                                            console.log(e);
                                        });

                                })
                                .catch(e => {
                                    console.log(e);
                                })
                        );
                    });

                    const getProductScientificSpeciesData = new Promise((resolve, reject) => {
                        resolve(ProductScientificSpeciesDataService.getOnlyMeat()
                            .then((_speciesResponse) => {
                                SetSpecies(_speciesResponse.data);
                            })
                            .catch((e) => {
                                console.log(e);
                            })
                        );
                    });
                    const getProductData = new Promise((resolve, reject) => {
                        resolve(
                            ProductService.getById(productId)
                                .then(response => {
                                    console.log(response.data.data);
                                    SetProduct(response.data.data);
                                    setIsGEFS(response.data.data.isGEFS);
                                }, [])
                                .catch(e => {
                                    console.log(e);
                                })
                        )
                    });


                    const getMemberStateData = new Promise((resolve, reject) => {
                        resolve(
                            ZoneService
                                .getAllByGroup("ALL_MEMBER_STATES")
                                .then((_countryResponse) => {
                                    const options = _countryResponse.data.map(d => ({
                                        "id": d.id,
                                        "displayName": d.displayName,
                                        "countryName": d.countryName,
                                        "regionName": d.regionName,
                                        "isoCode": d.isoCode,
                                    })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                                    SetMemberStates(options);
                                })
                        );
                    });
                    const getBSEControlledRiskData = new Promise((resolve, reject) => {
                        resolve(
                            ZoneService
                                .getAllByGroup("ALL_BSE_CONTROLLED_RISK")
                                .then((response) => {
                                    const options = response.data.map(d => ({
                                        "id": d.id,
                                        "displayName": d.displayName,
                                        "countryName": d.countryName,
                                        "regionName": d.regionName,
                                        "isoCode": d.isoCode,
                                    })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                                    SetControlledRisk(options);
                                })
                        );
                    });
                    const getBSENegligbleRiskData = new Promise((resolve, reject) => {
                        resolve(
                            ZoneService
                                .getAllByGroup("ALL_BSE_NEGLIGBLE_RISK")
                                .then((response) => {
                                    const options = response.data.map(d => ({
                                        "id": d.id,
                                        "displayName": d.displayName,
                                        "countryName": d.countryName,
                                        "regionName": d.regionName,
                                        "isoCode": d.isoCode,
                                    })).sort((a, b) => a.displayName.localeCompare(b.displayName));
                                    SetNegligbleRisk(options);
                                })
                        );
                    });
                    const getProductMeatPreparationData = new Promise((resolve, reject) => {
                        resolve(
                            ProductMeatPreparationService.getByProductId(productId)
                                .then(response => {
                                    var res = response.data;
                                    setColdStoreEstab(res.coldStoreEstablishments);
                                    setManufacturingPlantEstab(res.manufacturingPlantEstablishments);
                                    if (response.data == "") {
                                        return;
                                    }
                                    SetApiValues(res);
                                    if (res !== null) {
                                        setEditing(true);
                                    }
                                }), [])
                            .catch(e => {
                                console.log(e);
                            })
                    });
                    Promise.all([getProductMeatPreparationData, getProductScientificSpeciesData, populateProductDetailData, getProductData, getMemberStateData, getBSEControlledRiskData, getBSENegligbleRiskData]).then((responses) => {
                        setReady(true);
                    });
                } catch (e) {
                    console.error(e);
                }
            };

        }
        fetchListData();
    }, [ready]);

    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create, scopes.productnongefs.create, scopes.productnongefs.update]));
    }, []);

    const extractIsoCodes = (values, memberStates) => {
        const isoCodes = [];

        if (values?.animal_2_1_A) isoCodes.push(...values.animal_2_1_zone.map(x => x.isoCode));
        if (values?.animal_2_1_B) isoCodes.push(...values.animal_2_1_B_zone.map(x => x.isoCode));
        if (values?.animal_2_1_C) isoCodes.push(...memberStates.map(x => x.isoCode));
        return isoCodes.map(str => str.replace(/-(0|1|2|3|4|5)/g, ''));
    };

    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>

            {formSubmitted ? (
                <SubmissionPage
                    productId={productId}
                    attestationId={attestationId}
                    isGEFS={isGEFS}
                    errors={errors}
                    title={(editing ? "Edited" : "Completed") + " Meat Preparation 8383 Form"}
                    message={`Meat Preparation information has been successfully ${(editing ? "updated" : "added")} for ${product.productName} (Product Code: ${product.productCode} EAN: ${product.ean}).`}
                />

            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={true}
                        validateOnBlur={true}
                        enableReinitialize={true}
                    >
                        {(props) => (

                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Form>
                                        <p>Product: {product.productName} (Product Code: {product.productCode}, EAN: {product.ean})</p>
                                        <hr />
                                        <div className="">
                                            <h6>Meat Preparation products that:</h6>
                                            <ul className="list-unstyled list-clauses">

                                                {/* <FormController
                                                    control="input"
                                                    type="text"
                                                    label="Coldstore Approval Number"
                                                    name="coldStore"
                                                    col={3}
                                                />
                                                <FormController
                                                    control="input"
                                                    type="text"
                                                    label="Address Of Coldstore"
                                                    name="coldStoreNameAddress"
                                                    col={3}
                                                /> */}

                                                <ColdStoreSelect
                                                    name="coldStoreEstablishments"
                                                    selectedFieldName="coldStoreEstablishments"
                                                    setEstablishmentSelected={props.setFieldValue}
                                                    selections={props.values.coldStoreEstablishments}
                                                    label="Cold Stores"
                                                    isMulti={true}
                                                    countries={["GB"]}
                                                    certificateNumber={[certificateNumber]}
                                                    getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                    getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                    isRequired={false}
                                                    readOnly={readOnly}
                                                />

                                                <MultiSelectField
                                                    id='productScientificSpecies'
                                                    name='productScientificSpecies'
                                                    label="Species"
                                                    placeholder="Select fields"
                                                    options={specieslist}
                                                    defaultValues={props.values?.productScientificSpecies}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.speciesName + (option.alternativeName ? " (" + option.alternativeName + ")" : "")}
                                                    value={props.values.productScientificSpecies}
                                                    isMulti={true}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.productScientificSpecies}
                                                    error={props.errors.productScientificSpecies}
                                                    isClearable={true}
                                                    isrequired="true"
                                                    backspaceRemovesValue={true}
                                                    col={4}
                                                />

                                                {/* <>{JSON.stringify(groupingsArr)}</> */}
                                                <FieldArray name="meatData.speciesList">
                                                    {arrayHelpers => {
                                                        return (
                                                            <SpeciesTable
                                                                prefix={"meatData"}
                                                                productType="meat preparation"
                                                                speciesArrayHelpers={arrayHelpers}
                                                                meatGroups={meatGroups}
                                                                countrys={null}
                                                                zonesarray={groupingsArr}
                                                                requireSpeciesDetail={true}
                                                                requireTreatments={false}
                                                                requireCountryOfOrigin={true}
                                                                customCountryOfOriginLabel={"Meat constituent origin"}
                                                                requireEstablishmentCodes={false}
                                                                errors={props.errors}
                                                                disableCountryAndEstablishmentCodes={false}
                                                                readOnly={readOnly}
                                                                productId={productId}
                                                            />
                                                        );
                                                    }}
                                                </FieldArray>


                                                <h4 className="mt-5">Health Attestation</h4>

                                                <p>The <strong>meat preparations</strong> contain the following meat constituents and meet the criteria indicated below:
                                                </p>

                                                <ul className="list-unstyled">
                                                    {(speciesNotGame) && (
                                                        <li>
                                                            <FormController
                                                                control="checkbox"
                                                                label={MeatPreparation8383Labels.health_1_2[0].key}
                                                                name="health_1_2_1"
                                                                isrequired="true"
                                                                readOnly={readOnly}
                                                            />
                                                        </li>
                                                    )}
                                                    {(speciesIsGame) && (
                                                        <FormController
                                                            control="checkbox"
                                                            label={MeatPreparation8383Labels.health_1_2[1].key}
                                                            name="health_1_2_2"
                                                            isrequired="true"
                                                            readOnly={readOnly}
                                                        />
                                                    )}

                                                    {(props.values.meatData.containsPOR || (RUF)) && (
                                                        <li>
                                                            <FormController
                                                                control="checkbox"
                                                                label={MeatPreparation8383Labels.health_1_9_1.label}
                                                                name="health_1_9_1"
                                                                readOnly={readOnly}
                                                            />

                                                            {props.values.health_1_9_1 && (
                                                                <div className=" subform">
                                                                    <FormController
                                                                        control="checkboxClauses"
                                                                        label=""
                                                                        name="health_1_9_1_statements"
                                                                        options={MeatPreparation8383Labels.health_1_9_1_statements}
                                                                        readOnly={readOnly}
                                                                    />
                                                                </div>
                                                            )}
                                                        </li>
                                                    )}

                                                    {(props.values.meatData.containsSUW) && (
                                                        <li>
                                                            <FormController
                                                                control="checkbox"
                                                                label={MeatPreparation8383Labels.health_1_3_2.label}
                                                                name="health_1_3_2_1"
                                                                readOnly={readOnly}
                                                            />

                                                            {props.values.health_1_3_2_1 && (
                                                                <div className=" subform">
                                                                    <FormController
                                                                        control="radio"
                                                                        label=""
                                                                        name="health_1_3_2_1_statements"
                                                                        options={MeatPreparation8383Labels.health_1_3_2_1_statements}
                                                                        readOnly={readOnly}
                                                                    />
                                                                </div>
                                                            )}
                                                        </li>
                                                    )}

                                                    {(props.values.meatData.containsBOV || props.values.meatData.containsOVI) && (
                                                        <li>
                                                            <FormController
                                                                control="checkbox"
                                                                label={MeatPreparation8383Labels.health_1_11.label}
                                                                name="health_1_11"
                                                                readOnly={readOnly}
                                                            />
                                                        </li>
                                                    )}

                                                    {/* 
                                                    {(props.values.meatData.containsEQW) && (
                                                        <li>
                                                            <FormController
                                                                control="checkbox"
                                                                label={MeatPreparation8383Labels.health_1_12.label}
                                                                name="health_1_12"
                                                                readOnly={readOnly}
                                                            />

                                                            {props.values.health_1_12 && (
                                                                <div className=" subform">
                                                                    <FormController
                                                                        control="checkboxClauses"
                                                                        label=""
                                                                        name="health_1_12_statements"
                                                                        options={MeatPreparation8383Labels.health_1_12_statements}
                                                                        readOnly={readOnly}
                                                                    />
                                                                </div>
                                                            )}
                                                        </li>
                                                    )} */}

                                                    {(props.values.meatData.containsRUF) && (
                                                        <li>
                                                            <FormController
                                                                control="checkbox"
                                                                label={MeatPreparation8383Labels.health_1_13.label}
                                                                name="health_1_13"
                                                                readOnly={readOnly}
                                                            />
                                                        </li>
                                                    )}

                                                    {(props.values.meatData.containsRUW || (RUF)) && (
                                                        <li>
                                                            <FormController
                                                                control="checkbox"
                                                                label={MeatPreparation8383Labels.health_1_14.label}
                                                                name="health_1_14"
                                                                readOnly={readOnly}
                                                            />
                                                        </li>
                                                    )}
                                                </ul>

                                                {(props.values.meatData.containsBOV || props.values.meatData.containsOVI || props.values.meatData.containsRUW) && (
                                                    <ul className='list-unstyled' >
                                                        <li>
                                                            {/* <FormController
                                                               id="countryOriginId"
                                                               name="countryOriginId"
                                                               label="Country of origin of meat preparation product"
                                                               placeholder="Select a country of origin"
                                                               control="multiselect"
                                                               options={countrys}
                                                               isMulti={false}
                                                               isClearable={true}
                                                               onBlur={props.setFieldTouched}
                                                               onChange={props.setFieldValue}
                                                               touched={props.touched.countryOriginId}
                                                               errors={props.errors.countryOriginId}
                                                               // defaultValues={props.values.countryOriginId}
                                                               isrequired="true"
                                                               backspaceRemovesValue={true}
                                                               col={4}
                                                               readOnly={readOnly}
                                                           /> */}
                                                            <MeatBseRisks
                                                                title="meat preparation product"
                                                                formNumber="8383"
                                                                values={props.values}
                                                                labels={MeatPreparation8383Labels}
                                                                readOnly={readOnly}
                                                            />
                                                        </li>
                                                    </ul>
                                                )}
                                                {AnimalHealth && (
                                                    <><h4 className="mt-5">Animal Health Attestation</h4><p>The <strong>meat preparations</strong> contain the following meat constituents and meet the criteria indicated below: </p><ul className='list-unstyled'>
                                                        <li>
                                                            <span className='required'>Has been processed in and dispatched from the following zone(s):</span>
                                                            <MultiSelectField
                                                                id='meatpreparation'
                                                                name='animal_2_1_zone'
                                                                placeholder="Select fields"
                                                                options={props.values.zones1box}
                                                                defaultValues={props.values.animal_2_1_zone}
                                                                getOptionValue={option => option.id}
                                                                getOptionLabel={option => option.displayName}
                                                                value={props.values.animal_2_1_zone}
                                                                isMulti={true}
                                                                onChange={props.setFieldValue}
                                                                onBlur={props.setFieldTouched}
                                                                touched={props.touched.animal_2_1_zone}
                                                                error={props.errors.animal_2_1_zone}
                                                                isClearable={true}
                                                                col={6}
                                                                isrequired="true"
                                                                backspaceRemovesValue={true}
                                                                readOnly={readOnly} />
                                                        </li>
                                                        <p>Is authorised entry into the Union of <strong>fresh meat</strong> of the species described under point II2.2 from which the fresh meat was obtained and listed in Part 1
                                                            of Annex XIII to Commission Implementing Regulation (EU) 2021/404 for fresh meat of ungulates or in Part 1 of Annex XIV to Implementing Regulation (EU) 2021/404 for fresh meat of poultry and game birds,
                                                            and contains only fresh meat obtained in:</p>
                                                        <label className="mt-3 required">Currently are authorised:</label>
                                                        <FormController
                                                            control="checkbox"
                                                            label={MeatPreparation8383Labels.animal_2_1ABC[0].key}
                                                            name="animal_2_1_A"
                                                            readOnly={readOnly} />
                                                        <li>

                                                            <FormController
                                                                control="checkbox"
                                                                label={MeatPreparation8383Labels.animal_2_1ABC[1].key}
                                                                name="animal_2_1_B"
                                                                readOnly={readOnly} />
                                                        </li>

                                                        {props.values.animal_2_1_B && (
                                                            <div className="">
                                                                {(speciesIsUngulates) && (
                                                                    <li>

                                                                        <FormController
                                                                            control="checkbox"
                                                                            label={MeatPreparation8383Labels.animal_2_1[0].key}
                                                                            name="animal_2_1_1"
                                                                            isrequired="true"
                                                                            readOnly={readOnly} />

                                                                    </li>
                                                                )}
                                                                {(speciesNotUngulates) && (
                                                                    <li>
                                                                        <FormController
                                                                            control="checkbox"
                                                                            label={MeatPreparation8383Labels.animal_2_1[1].key}
                                                                            name="animal_2_1_2"
                                                                            isrequired="true"
                                                                            readOnly={readOnly} />
                                                                    </li>
                                                                )}


                                                            </div>
                                                        )}
                                                        {props.values.animal_2_1_B && (
                                                            <MultiSelectField
                                                                id='animal_2_1_B_zone'
                                                                name='animal_2_1_B_zone'
                                                                label="Select zone"
                                                                placeholder="Select fields"
                                                                options={props.values.zones1box}
                                                                defaultValues={props.values.animal_2_1_B_zone}
                                                                getOptionValue={option => option.id}
                                                                getOptionLabel={option => option.displayName}
                                                                value={props.values.animal_2_1_B_zone}
                                                                isMulti={true}
                                                                onChange={props.setFieldValue}
                                                                onBlur={props.setFieldTouched}
                                                                touched={props.touched.animal_2_1_B_zone}
                                                                error={props.errors.animal_2_1_B_zone}
                                                                isClearable={true}
                                                                col={6}
                                                                isrequired="true"
                                                                backspaceRemovesValue={true}
                                                                readOnly={readOnly} />
                                                        )}
                                                        <br></br>
                                                        <FormController
                                                            control="checkbox"
                                                            label={MeatPreparation8383Labels.animal_2_1ABC[2].key}
                                                            name="animal_2_1_C"
                                                            readOnly={readOnly} />

                                                    </ul></>
                                                )}

                                                <SlaughterhouseSelect
                                                    name="abattoirEstablishments"
                                                    selectedFieldName="abattoirEstablishments"
                                                    setEstablishmentSelected={props.setFieldValue}
                                                    selections={props.values.abattoirEstablishments}
                                                    label="Abattoirs"
                                                    isMulti={true}
                                                    countries={extractIsoCodes(props.values, memberStates)}
                                                    species={
                                                        props.values?.meatData.speciesList
                                                            .map(x => x?.speciesGroupName)
                                                    }
                                                    certificateNumber={[certificateNumber]}
                                                    getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                    getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                    isRequired={true}
                                                    readOnly={readOnly}
                                                />
                                                <SiteSelect
                                                    name="manufacturingPlantEstablishments"
                                                    selectedFieldName="manufacturingPlantEstablishments"
                                                    setEstablishmentSelected={props.setFieldValue}
                                                    selections={props.values.manufacturingPlantEstablishments}
                                                    label="Manufacturing Plant"
                                                    isMulti={true}
                                                    countries={extractIsoCodes(props.values, memberStates)}
                                                    certificateNumber={certificateNumber}
                                                    getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                    getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                    isRequired={false}
                                                    readOnly={readOnly}
                                                />
                                                <MultiSelectField
                                                    id='identificationMarkEstablishment'
                                                    name='identificationMarkEstablishment'
                                                    label="Identification mark"
                                                    placeholder="Select fields"
                                                    options={Array.from(new Map([...(manufacturingPlantEstab || []), ...(siteEstablishment || []), ...(coldStoreEstab || [])].map(item => [item["approvalNumber"], item])).values())} getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                    isMulti={false}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.identificationMarkEstablishment}
                                                    error={props.errors.identificationMarkEstablishment}
                                                    isClearable={true}
                                                    isrequired="true"
                                                    backspaceRemovesValue={true}
                                                    readOnly={readOnly}
                                                    col={6}
                                                />

                                                <label className={storageRequired ? "required pt-3" : " pt-3"}>Storage Life Date(s) <span className='text-gray-600 text-italic'>As per Notes Box reference 1.27 of EHC 8383</span></label>

                                                <FieldArray
                                                    name="storageDates"
                                                    render={({ insert, remove, push }) => (
                                                        <div>
                                                            {props.values?.storageDates?.length > 0 &&
                                                                props.values?.storageDates?.map((x, index) => (
                                                                    <Row key={1 + index}>
                                                                        <Col md={3} lg={2}>
                                                                            <FormController
                                                                                label={`Storage Life: ${1 + index}`}
                                                                                isrequired={storageRequired ? "true" : "false"}
                                                                                control="dateInput"
                                                                                name={`storageDates[${index}].storageDate`}
                                                                                value={props.values?.storageDates?.[index].storageDate ? moment(props.values?.storageDates?.[index].storageDate).format("DD/MM/YYYY") : ""}
                                                                            />
                                                                        </Col>
                                                                        <Col md={3} lg={2} className="mt-5">
                                                                            {(index > 0 &&
                                                                                <Button variant="outline-danger" disabled={readOnly} onClick={() => remove(index)}>
                                                                                    <FontAwesomeIcon icon={faTimes} className="mx-2" />
                                                                                </Button>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                ))}
                                                            <Button variant="light" className="mt-3 mb-2" onClick={() => push({ storageDate: "" })}>
                                                                Add a Storage Date
                                                            </Button>
                                                        </div>
                                                    )}
                                                />


                                            </ul>
                                        </div>
                                        {/* <PrettyPrintJson data={ props.values } /> */}
                                        <div className="form-group">
                                            <hr />
                                            {(Object.keys(props.errors).length > 0 && submitAttempted) &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            <Button type="submit" onClick={() => setSubmitAttempted(true)} disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            <BackButton
                                                isGEFS={isGEFS}
                                                productId={productId}
                                                attestationId={attestationId}
                                            />

                                        </div>
                                        <ChangeFormValues />
                                    </Form>
                                </Card.Body>
                            </Card>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default MeatPreparation8383AddPage;