import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faStamp, faBullhorn, faDownload, faSave, faFilePdf, faCog} from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Button, Alert, Card, Modal, Form } from '@themesberg/react-bootstrap';
import BlobService from "../../../services/BlobService";
import EhcGenerateService from '../../../services/EhcGenerateService';
import ConsignmentService from '../../../services/ConsignmentService';
import CertificateCompletedService from '../../../services/CertificateCompletedService';
import Progress from "../../../../components/Progress";
import moment from "moment-timezone";
import ConsignmentContentsService from '../../../services/ConsignmentContentsService';
import CertificateAssignedService from '../../../services/CertificateAssignedService';
import CertificateCompletedCompoundService from '../../../services/CertificateCompletedCompoundService';
import EhcCompletedService from '../../../services/EhcCompletedService';
import CrossingTypeModal from '../../../../pages/components/Certificates/CertificateEditor/Modals/CrossingTypeModal';

const ConsignmentContentsCertificatePanel = (props) => {
  const {
    fetchListData,
    isApproved,
    consignmentId,
    setGenerated,
    isComplete,
    setComplete,
    setFormCompleted,
    isGenerated,
    isGenerateReady,
    apiDailyAttestationValues,
    contentsSelected,
    languageCode
  } = props;

  const [running, setRunning] = useState(false);
  const [showModalGenerating, setShowModalGenerating] = useState(false);
  const [showModalCompleting, setShowModalCompleting] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isGenerating, setGenerating] = useState(false);
  const [completing, setCompleting] = useState(false);
  const [currentGenerating, setCurrentGenerating] = useState(0);
  const [currentCompleting, setCurrentCompleting] = useState(0);
  const [generatingCount, setTotalGenerating] = useState(0);
  const [completingCount, setCompletingTotal] = useState(0);
  const [generatingPercentage, setGeneratingPercentage] = useState(null);
  const [completingPercentage, setCompletingPercentage] = useState(null);
  const [generateTime, setGenerateTime] = useState('');
  const [completeTime, setCompleteTime] = useState('');
  const [populateData, setPopulateData] = useState([]);
  const [progressMessage, setProgressMessage] = useState('');

  const [crossingType, setCrossingType] = useState(localStorage.getItem('crossingType') || 'Zigzag');
  const [showCrossingTypeModal, setShowCrossingTypeModal] = useState(false);
  const [selectedCrossingType, setSelectedCrossingType] = useState(crossingType);

  const setErrorList = (errorList) => {
    const res = [];
    if (errorList != null) {
      errorList.forEach((img) => {
        if (!res.includes(img)) {
          res.push(img);
        }
      });
    }
    setErrorMessages(res);
  };

  const _handleRollBack = async () => {
    for (const item of apiDailyAttestationValues) {
      const id = item.id;
      try {
        const response = await EhcGenerateService.deleteGeneratedCertificates(id);
        const result = response.data;
        console.log("Rollback successful for ID:", id);
      } catch (error) {
        console.error("Error during rollback for ID:", id, error);
      }
    }
    window.location.reload();
  };


  const handleCloseModal = () => {
    setShowModalGenerating(false);
    setShowModalCompleting(false);
  };

  const handleCloseCrossingTypeModal = async () => {
    setShowCrossingTypeModal(false);
  };

  const handleConfirmCrossingTypeModal = async () => {
    setCrossingType(selectedCrossingType);
    localStorage.setItem('crossingType', selectedCrossingType);
    setShowCrossingTypeModal(false);
    await _handleRollBack();
  };

  useEffect(() => {
    console.log('crossingType has changed to:', crossingType);
  }, [crossingType]);

  const _generateEHCs = async (id, crossingType) => {
    setErrorMessages([]);
    setRunning(true);
    setGeneratingPercentage(0);
    setGenerating(true);
    setShowModalGenerating(true);
    try {
      await EhcGenerateService.match(id)
        .then(async matchResponse => {
          let certificateErrorList = [];
          const certificatesToGenerate = [];
          const { data, errors } = matchResponse.data;
          if (errors !== null && errors !== undefined) {
            setErrorList(errors);
          }
          // Filter out secondary combinations
          data.filter(x => x !== null).forEach(x => {
            const findSecondaryCombination = x.consignmentContents?.consignmentContentsCombinations?.find(x => x.isPrimary === false);
            if (!findSecondaryCombination) {
              certificatesToGenerate.push(x);
            }
          });
          if (certificatesToGenerate !== null) {
            let counter = 0;
            let foundCompoundIds = [];
            const totalCertificates = certificatesToGenerate.length;
            setGeneratingPercentage(1);
            setTotalGenerating(totalCertificates);
            setRunning(true);
            for (const assignedCert of certificatesToGenerate) {
              counter++;
              setCurrentGenerating(counter);
              const totalCompound = certificatesToGenerate.filter(x => x.consignmentContentsId === assignedCert.consignmentContentsId)?.length;
              if (totalCompound > 1) {
                if (!foundCompoundIds.includes(assignedCert.ConsignmentContents)) {
                  const certificateNumbers = assignedCert?.consignmentContents?.relatedAttestation[0]?.certificateNumberList;
                  const obj = {
                    certificateCount: certificateNumbers.length,
                    certificateNumbers: certificateNumbers,
                    consignmentContentsId: assignedCert.consignmentContentsId
                  };
                  foundCompoundIds.push(obj);
                }
              }
              const certificateId = assignedCert.certificateId;
              try {
                const response = await EhcGenerateService.populateAssignedCertificate(
                  assignedCert,
                  languageCode,
                  certificateId,
                  crossingType
                );
                const result = response.data;
                setPopulateData((prevData) => [...prevData, result]);
              } catch {
                certificateErrorList.push(`Certificate ${counter} didn't generate due to a technical issue.`);
                setErrorList(certificateErrorList);
                continue;
              } finally {
                if (totalCertificates === counter) {
                  if (foundCompoundIds.length > 0) {
                    try {
                      await Promise.all(foundCompoundIds.map(async x => {
                        const response = await EhcGenerateService.generateCompoundCertificate(
                          x.consignmentContentsId,
                          languageCode,
                          0,
                          x.certificateCount
                        );
                        const result = response.data;
                        GenerateComplete();
                      }));
                    } catch (e) {
                      GenerateComplete();
                    }
                  } else {
                    GenerateComplete();
                  }
                }
              }
            }
          } else {
            GenerateComplete();
          }
        })
        .catch(e => {
          if (e?.response !== undefined) {
            const { data, errors } = e.response;
            setErrorMessages([data?.Detail]);
            setErrorList(errorMessages);
            GenerateComplete();
          }
          GenerateComplete();
        });
    } catch (e) {
      GenerateComplete();
    }
  };

  const _complete = async (consignmentId) => {
    setErrorMessages([]);
    setCompletingPercentage(0);
    setCompleting(true);
    setShowModalCompleting(true);
    try {
      const certificateErrorList = [];
      const consignmentContentsResponse = await ConsignmentContentsService.getAllByConsignmentId(consignmentId);
      const consignmentContents = consignmentContentsResponse.data;
      let counter = 0;
      const total = consignmentContents.length;
      setCompletingTotal(total);
      for (const content of consignmentContents) {
        let assignedCounter = 0;
        setCurrentCompleting(counter + 1);
        const isCompound = content.isCompound;
        const assignedCertificateResponse = await CertificateAssignedService.getByConsignmentContentsId(content.id);
        if (!assignedCertificateResponse) {
          certificateErrorList.push("Can't find certificates");
          setErrorList(certificateErrorList);
          return null;
        }
        const assignedCertificates = assignedCertificateResponse.data;
        for (const assignedCertificate of assignedCertificates) {
          const existing = await CertificateCompletedService.getByConsignmentContentsId(assignedCertificate.consignmentContentsId);
          const existingData = existing.data;
          if (existingData?.length > 0) {
            await Promise.all(existingData.map(async x => { await CertificateCompletedService.remove(x.id); }));
          }
          console.log("assignedCertificate", assignedCertificate);
          const populatedResponse = await EhcCompletedService.populateCertficateCompleted(
            assignedCertificate.consignmentContentsId,
            assignedCertificate.certificateId,
            languageCode
          );
          console.log("populatedResponse", populatedResponse);
          const documentId = populatedResponse.data;
          const certificateCompleted = {
            consignmentContentsId: assignedCertificate.consignmentContentsId,
            documentId: documentId,
            certificateId: assignedCertificate.certificateId,
            isCompound: isCompound
          };
          console.log("certificateCompleted", certificateCompleted);
          const createComplete = await CertificateCompletedService.create(certificateCompleted);
          const response = createComplete.data;
          if (response !== null && response !== undefined) {
            // Handle response if needed
          }
          if (isCompound) {
            const existingCompound = await CertificateCompletedCompoundService.getByConsignmentContentsId(assignedCertificate.consignmentContentsId);
            const existingCompoundData = existingCompound.data;
            if (existingCompoundData?.length > 0) {
              await Promise.all(existingCompoundData.map(async x => { await CertificateCompletedCompoundService.remove(x.id); }));
            }
            const compoundDocument = await EhcCompletedService.generateCompletedCompoundCertificate(assignedCertificate.consignmentContentsId);
            const compoundDocumentId = await compoundDocument.data;
            const certificateCompletedCompound = {
              consignmentContentsId: assignedCertificate.consignmentContentsId,
              documentId: compoundDocumentId
            };
            const res = await CertificateCompletedCompoundService.create(certificateCompletedCompound);
            if (res.data !== null) {
              assignedCounter++;
              continue;
            }
          }
          assignedCounter++;
        }
        counter++;
        if (counter === total) {
          const createResponse = await ConsignmentService.complete(consignmentId);
          console.log(createResponse);
          setIsCompleted(true);
        }
      }
    } catch (e) {
      console.info(e);
      console.info(e.response);
      const certificateErrorList = [];
      certificateErrorList.push(`Sorry the consignment hasn't been completed due to a technical issue.`);
      setErrorList(certificateErrorList);
      CompleteFinished();
    }
  };

  const _downloadEHCs = (consignmentId) => {
    EhcCompletedService.downloadPack(consignmentId)
      .then(response => {
        const res = response.data;
        BlobService.downloadBlobZip(res);
      })
      .catch(e => {
        setErrorList(e.response.data);
      });
  };

  const handlePrint = async (consignmentId) => {
    try {
      const response = await EhcCompletedService.printPack(consignmentId);
      const pdfUrls = response.data;
      for (const url of pdfUrls) {
        BlobService.openBlobPdf(url);
      }
    } catch (e) {
      console.error("Error in handlePrint:", e);
    }
  };


  const GenerateComplete = () => {
    setGeneratingPercentage(100);
    setTimeout(() => {
      setGenerated(true);
      setGenerating(false);
      setRunning(false);
      handleCloseModal();
      fetchListData();
      setGeneratingPercentage(0);
      setTotalGenerating(0);
      setCurrentGenerating(0);
      setProgressMessage('');
    }, 2000);
  };

  const CompleteFinished = () => {
    setCompletingPercentage(100);
    setTimeout(() => {
      setComplete(true);
      setCompleting(false);
      handleCloseModal();
      fetchListData();
      setCompletingPercentage(0);
      setCompletingTotal(0);
      setCurrentCompleting(0);
      setProgressMessage('');
    }, 2000);
  };

  useEffect(() => {
    if (isCompleted) {
      CompleteFinished();
      setFormCompleted(true);
      setIsCompleted(false);
    }
  }, [isCompleted]);

  useEffect(() => {
    if (running) {
      const seconds = 40 * generatingCount;
      let duration = "";
      if (seconds > 59) {
        const m = moment.utc(moment.duration(seconds, "seconds").asMilliseconds());
        const roundUp = m.second() || m.millisecond() ? m.add(1, 'minute').startOf('minute') : m.startOf('minute');
        duration = roundUp.format("m") + " minutes";
      } else {
        duration = moment.utc(moment.duration(seconds, "seconds").asMilliseconds()).format("s") + " seconds";
      }
      const increment = 0.007 / contentsSelected.length;
      setGenerateTime(duration);
      const timeout = setTimeout(() => {
        setGeneratingPercentage((prev) => prev + increment);
      }, 1);
      if (generatingPercentage > 1 && generatingPercentage <= 99) {
        setProgressMessage(`Generating certificate ${currentGenerating} of ${generatingCount}`);
      }
      if (generatingPercentage >= 99) {
        clearTimeout(timeout);
      }
      return () => clearTimeout(timeout);
    }
  }, [running, generatingPercentage, contentsSelected.length, currentGenerating, generatingCount]);

  useEffect(() => {
    if (completing) {
      const seconds = 20 * completingCount;
      let duration = "";
      if (seconds > 59) {
        const m = moment.utc(moment.duration(seconds, "seconds").asMilliseconds());
        const roundUp = m.second() || m.millisecond() ? m.add(1, 'minute').startOf('minute') : m.startOf('minute');
        duration = roundUp.format("m") + " minutes";
      } else {
        duration = moment.utc(moment.duration(seconds, "seconds").asMilliseconds()).format("s") + " seconds";
      }
      const increment = 0.01 / contentsSelected.length;
      setCompleteTime(duration);
      const timeout = setTimeout(() => {
        setCompletingPercentage((prev) => prev + increment);
      }, 1);
      if (completingPercentage > 1 && completingPercentage <= 99) {
        setProgressMessage(`Stamping certificate ${currentCompleting} of ${completingCount}`);
      }
      if (completingPercentage >= 99) {
        clearTimeout(timeout);
      }
      return () => clearTimeout(timeout);
    }
  }, [completing, completingPercentage, contentsSelected.length, currentCompleting, completingCount]);

  return (
    <Card border="light" className="bg-white shadow-sm mb-4 w-100">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h4 className="text-title mb-0" id="tabelLabel">Export Health Certificates</h4>
          </Col>
          <Col xs="auto" className="d-flex justify-content-end">
            <Button
              variant="light"
              onClick={() => setShowCrossingTypeModal(true)}
              className="d-flex align-items-center"
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="d-flex flex-column">
        {((isGenerated && isComplete) || isApproved) ? (
          <div className="row">
            <div className="col-md-12">
              <p>This consignment has been completed.</p>
              <p className="mb-1">
                Download a zip file containing all of the completed certificates for upload to gov.uk.
              </p>
            </div>
            <div className="col-md-8 mb-2">
              <Button onClick={() => _downloadEHCs(consignmentId)} variant="tertiary" size="md">
                <FontAwesomeIcon icon={faDownload} className="me-2" />
                <span>Download Certificate Pack (ZIP)</span>
              </Button>
            </div>
            <div className="col-md-12">
              <p className="my-1">Open all completed certificates in your browser.</p>
            </div>
            <div className="col-md-10">
              <Button onClick={() => handlePrint(consignmentId)} variant="tertiary" size="md">
                <FontAwesomeIcon icon={faFilePdf} className="me-2" />
                <span>Display Completed Certificates (PDF)</span>
              </Button>
            </div>
          </div>
        ) : (
          <>
            {/* Generate Certificates Section */}
            <Row className={`${!isGenerateReady || isGenerating || apiDailyAttestationValues?.length <= 0
              ? "disabled opacity-50 align-items-center"
              : "align-items-center"}`}>
              <Col lg={8}>
                <strong>{isGenerated ? "Generate (Unmatched)" : "Generate"}</strong>
                <p>
                  {isGenerated
                    ? "Generate all ungenerated certificates in this consignment. This will not affect consignment contents already with certificates."
                    : "Generate certificates for this consignment once you have added all contents and saved changes."}
                  <small className="text-italic text-danger mb-0">
                    {" "}This may take a few minutes to process.
                  </small>
                </p>
              </Col>
              <Col lg={4} className="d-flex justify-content-end">
                <Button
                  disabled={!isGenerateReady || isGenerating || apiDailyAttestationValues?.length <= 0}
                  onClick={() => _generateEHCs(consignmentId, crossingType)}
                  variant={isGenerated ? "light" : "primary"}
                  size="md"
                  className={`my-1 w-100 ${isGenerated ? "text-dark" : "text-light"}`}
                >
                  <FontAwesomeIcon icon={faStamp} className="me-2" />
                  <span>{isGenerating ? "Generating..." : "Generate"}</span>
                </Button>
              </Col>
            </Row>

            {/* Complete Section */}
            <Row className={`${!isComplete && isGenerated
              ? "align-items-center"
              : "disabled opacity-50 align-items-center"}`}>
              <Col lg={8}>
                <strong>Complete</strong> the consignment once you have generated all the certificates.
                <br />
                <small className="text-italic text-danger mb-0">
                  Further changes cannot be made once a consignment has been completed.
                </small>
              </Col>
              <Col lg={4} className="d-flex justify-content-end">
                <Button
                  disabled={!isGenerateReady || isGenerating || apiDailyAttestationValues?.length <= 0}
                  onClick={() => _complete(consignmentId)}
                  variant="primary"
                  size="md"
                  className="my-1 w-100"
                >
                  <FontAwesomeIcon icon={faSave} className="me-2" />
                  <span>{isGenerating ? "Saving. Please wait..." : "Complete"}</span>
                </Button>
              </Col>
            </Row>

            {/* Modal for Generating */}
            <Modal size="lg" as={Modal.Dialog} centered show={showModalGenerating}>
              <Modal.Body>
                <div className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
                  <div className="container">
                    <div className="d-flex align-items-center justify-content-center col-12">
                      <div className="text-center p-4 p-lg-5 w-100 fmxw-500">
                        <h2 className="mb-5">Generating Certificate(s)</h2>
                        <p>
                          This will take an estimated {generateTime} to generate. Please wait on this screen until the process completes.
                        </p>
                        <div className="generateprogress">
                          <Progress value={generatingPercentage} variant="success" label="" size="xl" />
                          <label>{progressMessage}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            {/* Modal for Completing */}
            <Modal size="lg" as={Modal.Dialog} centered show={showModalCompleting}>
              <Modal.Body>
                <div className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
                  <div className="container">
                    <div className="d-flex align-items-center justify-content-center col-12">
                      <div className="text-center p-4 p-lg-5 w-100 fmxw-500">
                        <h2 className="mb-5">Completing Consignment</h2>
                        <p>
                          The final certificates are now being produced for the products in this consignment.
                          <br />
                          This will take an estimated {completeTime} to generate. Please wait on this screen until the process completes.
                        </p>
                        <div className="completeprogress">
                          <Progress value={completingPercentage} variant="success" label="" size="xl" />
                          <label>{progressMessage}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            {/* CrossingTypeModal */}
            <CrossingTypeModal
              show={showCrossingTypeModal}
              handleClose={handleCloseCrossingTypeModal}
              selectedCrossingType={selectedCrossingType}
              setSelectedCrossingType={setSelectedCrossingType}
              handleConfirm={handleConfirmCrossingTypeModal}
            />

            {/* Existing Confirmation Modal (if needed) */}
            <Modal size="md" as={Modal.Dialog} centered show={false} onHide={handleCloseModal}>
              <Modal.Header>
                <Modal.Title className="h6">Are you sure you want to remove?</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleCloseModal} />
              </Modal.Header>
              <Modal.Body>
                <div className="d-flex justify-content-end">
                  <Button variant="secondary" onClick={handleCloseModal} className="me-2">Cancel</Button>
                  <Button variant="danger" onClick={() => { /* your confirmRemove function */ }}>Remove</Button>
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}

        {/* Error Messages */}
        {errorMessages?.map((x, i) => (
          <Alert key={i} className="mt-2" variant="warning" size="sm">
            <div className="d-flex justify-content-start">
              <FontAwesomeIcon icon={faBullhorn} className="me-3" />
              {x}
            </div>
          </Alert>
        ))}
      </Card.Body>
    </Card>
  );
};

export default ConsignmentContentsCertificatePanel;
