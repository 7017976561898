import { Accordion, Card, Col, Row } from "@themesberg/react-bootstrap";
import { Form, Formik, getIn } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import EhcGenerateService from '../../../../services/EhcGenerateService';
import FormController from '../../../common/FormController';
import DocumentPanel from '../DocumentReader/DocumentPanel';
import FormGroup from '../Components/FormGroup';
import NavigationBar from "../Components/NavigationBar";
import EhcDataService from "../../../../services/EhcDataService";
import { getUserRoles } from "../../../../../auth/authorisation";

const SingleCertificateForm = (props) => {
    const {
        isCompound,
        updatedPdf,
        onUpdated,
        consignmentContentsId,
        certificateId,
        formData,
        languageChangePageNumber,
        certificateGroups,
        updating,
        setData,
        ready
    } = props;

    const [initialSavedData, setInitialSavedData] = useState([]);
    // const [ready, setReady] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [errors, setErrors] = useState(null);
    const [documentPath, setDocumentPath] = useState('');
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const canvas = useRef();
    let ctx = null;
    const [crossingType, setCrossingType] = useState(localStorage.getItem('crossingType') || 'Zigzag');

    const goToPrevPage = () =>
        setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

    const goToNextPage = () =>
        setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };


    const onSubmit = () => {

    }


    //Get Initial values
    useEffect(() => {
        if (ready && formData !== undefined) {
            var obj = {
                fields: formData?.fields,
                textStamps: formData?.textStamps,
                lineStamps: formData?.lineStamps,
                certificateGroups: formData?.certificateGroups,
            }
            setInitialValues(obj)
        }
    }, [ready]);

    //   useEffect(() => {
    //     // Code to execute when isRedact changes
    //     console.log('isRedact has changed to:', isRedact);
    //     alert("SingleCertificateForm " + isRedact)
    //     // Any other logic that needs to run when isRedact changes
    // }, [isRedact]); // Dependency array with isRedact


    function retrieveFormInitialData() {

        const getCertificateByteArray = new Promise((resolve, reject) => {
            resolve(EhcGenerateService.getCertificateFilename(consignmentContentsId, certificateId)
                .then((response) => {
                    var bytes = response.data;
                    setDocumentPath(`data:application/pdf;base64,${bytes}`);
                })
                .catch((e) => {
                    console.log(e);
                })
            );
        });

        const getInitialSavedData = new Promise((resolve, reject) => {
            resolve(EhcDataService.getSavedData(consignmentContentsId, certificateId)
                .then((response) => {
                    setInitialSavedData(response.data);
                })
                .catch((e) => {
                    console.log(e);
                })
            );
        });

        Promise.all([getInitialSavedData, getCertificateByteArray]).then((responses) => {
            //   });
        })
    }
    useEffect(() => {
        retrieveFormInitialData();
    }, []);

    useEffect(() => {
        if (updatedPdf !== undefined && updatedPdf !== null) {
            //temp - works for composite
            retrieveFormInitialData();
            //setDocumentPath(updatedPdf);
        }
    }, [updatedPdf]);


    const isParentDisabled = (values, parentGroupId) => {
        var parent = getIn(values.lineStamps, formData?.config?.lines?.find(x => x.groupId == parentGroupId && x.disableChildGroupsIfFalse === true).fieldName.replaceAll(" ", ""));

        if (parent === true) {
            return true;
        }
        return false;
    }

    const isParentOrChildDisabled = (values, parentGroupId, childGroupId) => {
        var parent = getIn(values.lineStamps, formData?.config?.lines?.find(x => x.groupId == parentGroupId && x.disableChildGroupsIfFalse === true).fieldName.replaceAll(" ", ""));
        var child = getIn(values.lineStamps, formData?.config?.lines?.find(x => (x.groupId == childGroupId) && x.disableChildGroupsIfFalse === true)?.fieldName?.replaceAll(" ", ""));
        if (parent == true || child == true) {
            return true;
        }
        return false;
    }
    const roles = getUserRoles();
    const isAdmin = roles.includes("admin");

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={onSubmit}
        >
            {(formik, setFieldValue) => (
                <Form key={certificateId}
                >
                    {/* //onChange={()=>_handleFormChange(
                formik.values)} */}
                    <Row className="mt-0">
                        <Col md={4}>
                            {/* {JSON.stringify(formik.values.fields)} */}
                            <Card border="light" className="h-100 bg-white shadow-sm">
                                <Card.Body>
                                    <Row className="mt-2">
                                        <Col md={3}>
                                            <label className="mt-2">Total Pages</label>
                                        </Col>
                                        <Col md={3}>
                                            <FormController
                                                control="input"
                                                type="number"
                                                name="fields.ofnumber"
                                            />
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className="mt-0">
                                        <Col md={12}>

                                            <p>
                                                Use the fields and options below to edit the information on this existing certificate, page by page.
                                            </p>
                                            {/*                                         
                                        <h5>page {pageNumber}</h5>
                                        <h5>languageChangePageNumber {languageChangePageNumber}</h5>
                                        <h5>formData {JSON.stringify(formData.config)}</h5> */}


                                            {/* //Nothing to edit */}
                                            {!formData?.config?.fields?.filter(x => x.pages?.includes(pageNumber)).length &&
                                                !(formData?.config?.textStamps?.filter(x => x.page === pageNumber).length ||
                                                    formData?.config?.lines?.filter(x => x.page === pageNumber).length) ? (
                                                <>
                                                    <p className="mt-3">
                                                        Nothing to edit on this page.
                                                    </p>
                                                </>
                                            )
                                                :
                                                (
                                                    <Accordion className={isAdmin ? "small d-block" : (languageChangePageNumber > pageNumber ? "small d-block" : "d-none")}>
                                                        {/* Groups without Children */}
                                                        {certificateGroups?.filter(y => y.pages?.includes(pageNumber) && y.parentGroupId === null)
                                                            .map((group, i) =>
                                                                <Accordion.Item key={`accordion-${i}`} eventKey={i} >
                                                                    <Accordion.Header>
                                                                        {group.groupName}
                                                                    </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        {/* Groups with Children */}
                                                                        {certificateGroups.filter(x => x.pages?.includes(pageNumber) && x.parentGroupId === group.groupId).length > 0 ?
                                                                            <>

                                                                                <FormGroup
                                                                                    canvas={canvas}
                                                                                    ctx={ctx}
                                                                                    data={formData?.config}
                                                                                    pageNumber={pageNumber}
                                                                                    languageChangePageNumber={languageChangePageNumber}
                                                                                    group={group}
                                                                                />
                                                                                {certificateGroups?.filter(y => y.pages?.includes(pageNumber) && y.parentGroupId === group.groupId).map((parentGroup, index) =>
                                                                                    <Accordion>
                                                                                        <Accordion.Item key={`accordionChild-${i}`} eventKey={index + "_" + parentGroup.id}>
                                                                                            <Accordion.Header>
                                                                                                {parentGroup.groupName}
                                                                                            </Accordion.Header>
                                                                                            <Accordion.Body>
                                                                                                {/* fd {formData?.config?.lines?.find(x=>x.groupId == parentGroup.groupId && x.disableChildGroupsIfFalse === true)?.fieldName?.replaceAll(" ","")}
             */}

                                                                                                {/* s{getIn(formik.values.lineStamps, formData?.config?.lines?.find(x=>x.groupId == parentGroup.groupId && x.disableChildGroupsIfFalse === true)?.fieldName?.replaceAll(" ",""))}
                                                            //Parent Group */}
                                                                                                <FormGroup
                                                                                                    canvas={canvas} ctx={ctx}
                                                                                                    data={formData?.config}
                                                                                                    pageNumber={pageNumber}
                                                                                                    languageChangePageNumber={languageChangePageNumber}
                                                                                                    group={parentGroup}
                                                                                                    parentGroup={group}
                                                                                                    setFieldValue={formik.setFieldValue}
                                                                                                />


                                                                                                {/* //Child Group */}
                                                                                                {parentGroup.parentGroupId !== null && (
                                                                                                    certificateGroups?.filter(z => z.pages?.includes(pageNumber) && z.parentGroupId === parentGroup.groupId).map((childGroup, index2) =>
                                                                                                        <Accordion>
                                                                                                            <Accordion.Item key={`accordiongrandchild-${i}`} eventKey={index2 + "_" + childGroup.id}>
                                                                                                                <Accordion.Header>
                                                                                                                    {childGroup.groupName}
                                                                                                                </Accordion.Header>
                                                                                                                <Accordion.Body>
                                                                                                                    <FormGroup
                                                                                                                        setFieldValue={formik.setFieldValue}
                                                                                                                        values={formik.values}
                                                                                                                        canvas={canvas}
                                                                                                                        ctx={ctx}
                                                                                                                        parentDisabled={isParentDisabled(formik.values, parentGroup.groupId)}
                                                                                                                        data={formData?.config}
                                                                                                                        pageNumber={pageNumber}
                                                                                                                        languageChangePageNumber={languageChangePageNumber}
                                                                                                                        group={childGroup}
                                                                                                                        parentGroup={parentGroup}
                                                                                                                    />
                                                                                                                    {/* //Grandchild Group */}
                                                                                                                    {childGroup.parentGroupId !== null && (
                                                                                                                        certificateGroups?.filter(a => a.pages?.includes(pageNumber) && a.parentGroupId === childGroup.groupId).map((grandChildGroup, index3) =>
                                                                                                                            <Accordion>
                                                                                                                                <Accordion.Item key={`accordiongreatgrandchild-${i}`} eventKey={index3 + "_" + grandChildGroup.id}>
                                                                                                                                    <Accordion.Header>
                                                                                                                                        {grandChildGroup.groupName}
                                                                                                                                    </Accordion.Header>
                                                                                                                                    <Accordion.Body>
                                                                                                                                        <FormGroup
                                                                                                                                            canvas={canvas}
                                                                                                                                            ctx={ctx}
                                                                                                                                            parentDisabled={isParentOrChildDisabled(formik.values, parentGroup.groupId, childGroup.groupId)}
                                                                                                                                            setFieldValue={formik.setFieldValue}
                                                                                                                                            data={formData?.config}
                                                                                                                                            pageNumber={pageNumber}
                                                                                                                                            languageChangePageNumber={languageChangePageNumber}
                                                                                                                                            group={grandChildGroup}
                                                                                                                                            parentGroup={childGroup}
                                                                                                                                        />
                                                                                                                                    </Accordion.Body>
                                                                                                                                </Accordion.Item>
                                                                                                                            </Accordion>
                                                                                                                        ))}
                                                                                                                </Accordion.Body>
                                                                                                            </Accordion.Item>
                                                                                                        </Accordion>
                                                                                                    ))}
                                                                                            </Accordion.Body>
                                                                                        </Accordion.Item>
                                                                                    </Accordion>
                                                                                )}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <FormGroup
                                                                                    canvas={canvas}
                                                                                    ctx={ctx}
                                                                                    data={formData?.config}
                                                                                    languageChangePageNumber={languageChangePageNumber}
                                                                                    pageNumber={pageNumber}
                                                                                    group={group}
                                                                                    setFieldValue={formik.setFieldValue}
                                                                                />
                                                                            </>

                                                                        }

                                                                    </Accordion.Body>
                                                                </Accordion.Item>

                                                            )}
                                                    </Accordion>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* //Right panel */}
                        <Col md={8} >
                            <NavigationBar
                                isCompound={isCompound}
                                numPages={numPages}
                                pageNumber={pageNumber}
                                goToNextPage={goToNextPage}
                                goToPrevPage={goToPrevPage}
                                data={formData?.config}
                                certificateGroups={certificateGroups}
                                setData={setData}
                                formData={formData}
                                onUpdated={() => { onUpdated(formik.values, formData?.config, certificateId, crossingType, "NO") }}
                                updating={updating}
                                values={formik.values}
                                //isSubmitting={isSubmitting}
                                initialSavedData={initialSavedData}
                                certificateId={certificateId}
                                // config={config}
                                crossingType={crossingType} setCrossingType={setCrossingType}
                            />

                            <Card border="light" className="mt-2 bg-white shadow-sm">
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <DocumentPanel
                                                documentPath={documentPath}
                                                formData={formData}
                                                setData={setData}
                                                pageNumber={pageNumber}
                                                numPages={numPages}
                                                onDocumentLoadSuccess={onDocumentLoadSuccess}
                                                canvas={canvas}
                                                ctx={ctx}
                                                updating={updating}
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik >
    )
}


export default SingleCertificateForm;