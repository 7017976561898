import *  as baseService from "./BaseService";

const match = async (consignmentId) => {
    return await baseService.postApiData("/EhcGenerate/MatchCertificatesToConsignment?consignmentId=" + consignmentId);
};

const populateAssignedCertificate = async (data, languageCode, certificateId, crossingType) => {
    return await baseService.postApiData("/EhcGenerate/PopulateAssignedCertificate?languageCode=" + languageCode + "&certificateId=" + certificateId + "&crossingType=" + crossingType, data);
};

const getCertificateFilename = (consignmentContentsId, certificateId) => {
    return baseService.getApiData("/EhcGenerate/getCertificateFilename?consignmentContentsId=" + consignmentContentsId + "&certificateId=" + certificateId);
};

const populateCertficate = (consignmentContentsId, certificateId, languageCode, crossingType, data) => {
    return baseService.postApiData("/EhcGenerate/PopulateCertficateByConsignmentContentsId?consignmentContentsId=" + consignmentContentsId + "&certificateId=" + certificateId + "&languageCode=" + languageCode + "&crossingType=" + crossingType, data);
};

const updateCertficate = (consignmentContentsId, languageCode, certificateId, crossingType, data) => {
    return baseService.postApiData("/EhcGenerate/UpdateCertificate?consignmentContentsId=" + consignmentContentsId + "&certificateId=" + certificateId + "&languageCode=" + languageCode + "&crossingType=" + crossingType, data);
};

const populateCertficateWithoutData = (consignmentContentsId) => {
    return baseService.postApiData("/EhcGenerate/PopulateCertficate?consignmentContentsId=" + consignmentContentsId, null);
};

const getCompoundCertificateFilename = (consignmentContentsId, certificateId) => {
    return baseService.getApiData("/EhcGenerate/GetCompoundCertificateFilename?consignmentContentsId=" + consignmentContentsId + "&certificateId=" + certificateId);
};

const getAllMatchedFilenamesByConsignmentId = (consignmentContentsId, certificateId) => {
    return baseService.getApiData("/EhcGenerate/GetAllMatchedFilenamesByConsignmentId?consignmentContentsId=" + consignmentContentsId + "&certificateId=" + certificateId);
};

const generateCompoundCertificate = (consignmentContentsId, languageCode, totalPages = 0, totalCerts) => {
    return baseService.postApiData("/EhcGenerate/GenerateCompoundCertificate?consignmentContentsId=" + consignmentContentsId + "&languageCode=" + languageCode + "&totalPages=" + totalPages + "&totalCerts=" + totalCerts);
};
const deleteGeneratedCertificates = (consignmentContentsId) => {
    return baseService.postApiData("/EhcGenerate/DeleteGeneratedCertificates?consignmentContentsId=" + consignmentContentsId);
};

const EhcGenerateService = {
    match,
    populateAssignedCertificate,
    getCertificateFilename,
    getCompoundCertificateFilename,
    getAllMatchedFilenamesByConsignmentId,
    populateCertficate,
    populateCertficateWithoutData,
    generateCompoundCertificate,
    updateCertficate,
    deleteGeneratedCertificates
};

export default EhcGenerateService;