import * as MeatPreparation8383Labels from "../MeatPreparation8383Labels"
import { findArrayElementByFieldNameAndValue, findArrayElementByValue } from "../../../Attestations/Common/AttestationFunctions";
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
export const AttestationContent = (props) => {
    const { attestationData } = props;
    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);
    return (
        <>
            <h5>II. Health Attestation</h5>
            <>
                <h6>II.1 Public Health Attestation</h6>
                <p>
                    I, the undersigned, declare that I am aware of the relevant requirements of Regulation (EC) No 999/2001 of the European Parliament and of the
                    Council, Regulation (EC) No 178/2002 of the European Parliament and of the Council, Regulation (EC) No 852/2004 of the European Parliament
                    and of the Council, Regulation (EC) No 853/2004 of the European Parliament and of the Council and Regulation (EU) 2017/625 of the European
                    Parliament and of the Council and certify that the meat preparations described in Section A.2 were produced in accordance with these requirements, in
                    particular that:
                </p>
                <ol className="attestation-II-1">
                    <li>
                        they come from (an) establishment(s) applying general hygiene requirements and implementing a programme based on the hazard analysis and critical
                        control points (HACCP) principles in accordance with Article 5 of Regulation (EC) No 852/2004, regularly audited by the competent authorities, and
                        being listed as an EU approved establishment;
                    </li>
                    <li>
                        {attestationData.health_1_2_1 == true && (<span>{findArrayElementByValue(MeatPreparation8383Labels.health_1_2, 1)}</span>)}
                        {attestationData.health_1_2_2 == true && (<span>{findArrayElementByValue(MeatPreparation8383Labels.health_1_2, 2)}</span>)}
                    </li>
                    <li>they have been produced in accordance with Section V of Annex III to Regulation (EC) No 853/2004 and frozen to an internal temperature of not more than -18°C;</li>
                    <li>they have been marked with an identification mark in accordance with Section I of Annex II to Regulation (EC) No 853/2004;</li>
                    <li>the label(s) affixed on the packaging of meat preparations described in Section A.2, bear(s) an identification mark to the effect that the meat preparations come wholly from fresh meat from establishments (slaughterhouses, game handling establishment and cutting plants) approved for the entry into the Union;</li>
                    <li>they satisfy the relevant criteria laid down in Commission Regulation (EC) No 2073/2005;</li>
                    <li>the guarantees covering live animals and products thereof provided by the control plan submitted in accordance with Article 6(2) of Commission Delegated Regulation (EU) 2022/2292 are fulfilled and the concerned animals and products are listed in Annex -I to Commission Implementing Regulation (EU) 2021/405 for the concerned third country or territory;</li>
                    <li>they have been stored and transported in accordance with the relevant requirements of Section V of Annex III to Regulation (EC) No 853/2004;</li>
                    <li>
                        they have been produced from raw material which meets the requirements of Sections I to IV of Annex III to Regulation (EC) No
                        853/2004; in particular that:
                        <ol className="attestation-II-1-9">
                            <>
                                {attestationData.health_1_9_1 == true ? (
                                    <li>the meat of <strong><u>domestic porcine animals</u></strong>, this meat fulfills the requirements of Commission Implementing Regulation (EU) 2015/1375, and
                                        in particular:
                                        <ul>
                                            {attestationData.health_1_9_1_statements.map(statement =>
                                                <li>
                                                    {findArrayElementByValue(MeatPreparation8383Labels.health_1_9_1_statements, statement)}
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                )
                                    :
                                    (
                                        <li>N/A</li>
                                    )}
                                {attestationData.health_1_3_2_1 == true ? (
                                    <li>
                                        if obtained from meat of <strong><u>solipeds or wild boar</u></strong>, this meat fulfils the requirements of Implementing Regulation (EU) 2015/1375, and in particular, has
                                        been subject to an examination by a digestion method for Trichinella with negative results;
                                        <ul>
                                            {attestationData.health_1_3_2_1_statements > 0 &&
                                                <li>
                                                    {findArrayElementByFieldNameAndValue(MeatPreparation8383Labels.health_1_3_2_1_statements, 'health_1_3_2_1_statements', attestationData.health_1_3_2_1_statements)}
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                )
                                    :
                                    (
                                        <li>N/A</li>
                                    )}
                            </>
                        </ol>
                    </li>
                    {attestationData.bseRiskCategoryNegligible || attestationData.bseRiskCategoryControlled || attestationData.bseRiskCategoryUndetermined ? (
                        <>
                            <li>the material from bovine, ovine or caprine animals, with regard to bovine spongiform encephalopathy (BSE):
                                {attestationData.bseRiskCategoryNegligible && (
                                    <ul>
                                        <li>the country or region of origin is classified in accordance with Commission Decision
                                            2007/453/EC as a country or region posing a <u>negligible BSE risk</u>, and

                                            <ul>
                                                {attestationData.bseRiskCategoryNegligibleStatements.map(statement =>
                                                    <li>
                                                        {findArrayElementByValue(MeatPreparation8383Labels.BseRiskCategoryNegligibleStatementsChoice, statement)}
                                                    </li>
                                                )}
                                            </ul>
                                        </li>
                                    </ul>
                                )}
                                {/* BSE Controlled */}
                                {attestationData.bseRiskCategoryControlled &&
                                    <ul>
                                        <li>the country or region of origin is classified in accordance with Decision 2007/453/EC
                                            as a country or region posing a <u>controlled BSE risk</u> , and

                                            <ul>
                                                <li>
                                                    {findArrayElementByValue(
                                                        MeatPreparation8383Labels.BseRiskCategoryControlledStatementsChoice, 1)
                                                    }
                                                </li>
                                                <li>
                                                    {findArrayElementByValue(
                                                        MeatPreparation8383Labels.BseRiskCategoryControlledStatementsChoice, 2)
                                                    }
                                                </li>
                                                {attestationData.bseRiskCategoryControlledStatements > 0 &&
                                                    <li>
                                                        {findArrayElementByValue(
                                                            MeatPreparation8383Labels.BseRiskCategoryControlledStatementsChoice,
                                                            attestationData.bseRiskCategoryControlledStatements)
                                                        }


                                                    </li>
                                                }
                                            </ul>
                                        </li>
                                    </ul>
                                }
                                {/* BSE Undetermined */}
                                {attestationData.bseRiskCategoryUndetermined &&
                                    <ul>
                                        <li>the country or region of origin has not been classified in accordance with Decision 2007/453/EC or is classified as a country or region with an <u>undetermined BSE risk</u>, and
                                            <ul>
                                                <li>
                                                    {findArrayElementByValue(MeatPreparation8383Labels.BseRiskCategoryUndeterminedStatementsChoice, 1)}
                                                </li>
                                                <li>
                                                    {findArrayElementByValue(MeatPreparation8383Labels.BseRiskCategoryUndeterminedStatementsChoice, 2)}
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                }
                            </li>
                        </>
                    ) :
                        (
                            <li>N/A</li>
                        )}

                    {attestationData.health_1_12_1 == true ? (
                        <li> if containing material from <strong><u>domestic solipeds</u></strong>, the fresh meat used in the preparation of the meat products
                            <ul>
                                {attestationData.health_1_12_1_statements > 0 &&
                                    <li>
                                        {findArrayElementByFieldNameAndValue(MeatPreparation8383Labels.health_1_12_statements, 'health_1_12_statements', attestationData.health_1_12_1_statements)}
                                    </li>
                                }
                            </ul>
                        </li>
                    )
                        :
                        (
                            <li>N/A</li>
                        )}


                    {attestationData.health_1_13 ? (
                        <li>{ReactHtmlParser(MeatPreparation8383Labels.health_1_13.label)}</li>
                    ) :
                        (
                            <li>N/A</li>
                        )}


                    {attestationData.health_1_14 ? (
                        <li>{ReactHtmlParser(MeatPreparation8383Labels.health_1_14.label)}</li>
                    ) :
                        (
                            <li>N/A</li>
                        )}

                </ol>
                <h6>II.2 Animal health attestation</h6>
                <p>The <strong>meat preparation</strong> described in Section A.2:</p>
                <ol type="1" className="attestation-II-2">
                    <li>
                        <strong><u></u></strong>
                        has been prepared in and dispatched from the <strong>zone</strong> with code:  <strong><u>{attestationData.animal_2_1_zone?.map(x => x.isoCode).join(", ")}</u></strong>,which, at the date of issue of this support attestation is authorised for
                        entry into the Union of <strong>fresh meat</strong> of the species described under point II.2.2 from which the fresh meat was obtained and listed in Part 1 of
                        Annex XIII to Commission Implementing Regulation (EU) 2021/404 for fresh meat of ungulates or in Part 1 of Annex XIV to Implementing
                        Regulation (EU) 2021/404 for fresh meat of poultry and game birds, and contains only fresh meat obtained in:
                        <ul>
                            {attestationData.animal_2_1_A == true &&
                                <li>
                                    the same zone as the zone of preparation and dispatch;<br />
                                </li>
                            }
                            {attestationData.animal_2_1_B == true &&
                                <li>
                                    the zone/s with code/s <strong><u>{attestationData.animal_2_1_B_zone.map(x => x.isoCode).join(", ")}</u></strong>  which, at the date of issue of this support attestation is/are authorised for the entry into the
                                    Union of fresh meat of the species from which the fresh meat has been obtained and listed in <br />


                                    {(attestationData.animal_2_1_1 || attestationData.animal_2_1_2) ? (
                                        <ul>
                                            {attestationData.animal_2_1_1 == true &&
                                                findArrayElementByValue(MeatPreparation8383Labels.animal_2_1, "1")
                                            }
                                            <br />
                                            {attestationData.animal_2_1_2 == true &&
                                                findArrayElementByValue(MeatPreparation8383Labels.animal_2_1, "2")
                                            }
                                        </ul>
                                    ) : (
                                        <li>N/A</li>
                                    )}
                                </li>
                            }
                            {attestationData.animal_2_1_C == true &&
                                <li>a Member State</li>
                            }
                        </ul>
                    </li>
                    <li>
                        contains only fresh meat complying with all the animal health requirements for entry into the Union of fresh meat laid down in the relevant
                        model certificate, of the following species: <strong><u>{attestationData.speciesList.map(x => x.speciesGroupName).join(", ")} and therefore eligible for the entry into the Union as such.</u></strong>
                    </li>
                </ol>
                <h6>II.3 Animal welfare attestation</h6>
                <p>I, the undersigned, hereby certify, that the Meat preparation described in Section A.2 derive from animals which have been treated in the slaughterhouse
                    in accordance with the requirements of the Union legislation on the protection of animals at the time of killing or at least equivalent requirements.</p>

                {/* <PrettyPrintJson data={attestationData} >  </PrettyPrintJson> */}
                {/* <PrettyPrintJson data={compositeInfo} >  </PrettyPrintJson>
            <PrettyPrintJson data={speciesList} >  </PrettyPrintJson>  */}
            </>


        </>
    )
}

