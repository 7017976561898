// ../Modals/CrossingTypeModal.jsx
import React from 'react';
import { Button, Modal, Form } from "@themesberg/react-bootstrap";

const CrossingTypeModal = ({
    show,
    handleClose,
    selectedCrossingType,
    setSelectedCrossingType,
    handleConfirm
}) => {
    return (
        <Modal size="md" as={Modal.Dialog} centered show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">Settings</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="crossingTypeSelect">
                        <Form.Label>Select Crossing Type</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedCrossingType}
                            onChange={(e) => setSelectedCrossingType(e.target.value)}
                        >
                            <option value="Zigzag">Line Crossings</option>
                            <option value="Redacted">Redacted</option>
                            <option value="Combined">Combined</option>
                            <option value="RedactedWithOutLine">Redacted With OutLine</option>
                        </Form.Control>
                    </Form.Group>
                    <br />
          <p className="text-danger fst-italic">
            <strong>Selecting "Confirm" will erase all your current progress and allow you to generate fresh certificates. Only proceed if you are certain that no team member is currently working on this consignment.</strong>
          </p>
           </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <Button variant="primary" onClick={handleConfirm}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CrossingTypeModal;
