import React, { useState, useEffect } from 'react';
import { Button, Col, Dropdown, Row } from "@themesberg/react-bootstrap";
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PermissionCheck from '../../../../../auth/PermissionCheck';
import AddGroupModal from '../Modals/AddGroupModal';
import ViewDataModal from '../Modals/ViewDataModal';
import ViewConfigModal from '../Modals/ViewConfigModal';
import CrossingTypeModal from '../Modals/CrossingTypeModal'; // Import the new modal
import { getUserRoles } from "../../../../../auth/authorisation";

const NavigationBar = ({
    isCompound = false,
    certificateId,
    values,
    pageNumber,
    numPages,
    goToPrevPage,
    goToNextPage,
    data,
    certificateGroups,
    setData,
    formData,
    updating,
    onUpdated,
    isSubmitting,
    initialSavedData,
    config,
    crossingType,
    setCrossingType
}) => {

    // State for Modals
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [showModalViewData, setShowModalViewData] = useState(false);
    const [showModalViewConfig, setShowModalViewConfig] = useState(false);
    const [showModalGenerating, setShowModalGenerating] = useState(false);
    const [showModalCompleting, setShowModalCompleting] = useState(false);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [selectedCrossingType, setSelectedCrossingType] = useState(crossingType);

    const handleDataObjectUpdate = (updatedData) => {
        setData(updatedData);
    };

    const roles = getUserRoles();
    const isAdmin = roles.includes("admin");

    useEffect(() => {
        console.log('crossingType has changed to:', crossingType);
    }, [crossingType]);

    // Handler Functions for Closing Modals
    const handleCloseModalAdd = () => setShowModalAdd(false);
    const handleCloseModalViewData = () => setShowModalViewData(false);
    const handleCloseModalViewConfig = () => setShowModalViewConfig(false);
    const handleCloseSettingsModal = () => setShowSettingsModal(false);

    // Handler for Confirming Settings Modal
    const handleConfirmSettingsModal = () => {
        setCrossingType(selectedCrossingType);
        localStorage.setItem('crossingType', selectedCrossingType);
        setShowSettingsModal(false);
    };


    //Paging functions

    return (
        <Row>
            <Col md={5}>
                <nav>
                    <Button
                        size="sm"
                        variant="light"
                        onClick={goToPrevPage}
                        className="previous"
                    >
                        Prev
                    </Button>
                    <Button
                        size="sm"
                        variant="light"
                        onClick={goToNextPage}
                        className="mx-2 next"
                    >
                        Next
                    </Button>
                    <small className='d-inline mx-3'>
                        Page {pageNumber} of {numPages}
                    </small>
                </nav>
            </Col>
            {/* <FormController
                control="checkbox"
                label="Redact"
                name="Redact"
                onChange={(e) => {
                    handleCheckboxChange(e)
                }}
            /> */}
            <Col md={7} className="text-right">

                {values !== null &&
                    <Button
                        size="sm"
                        variant="secondary"
                        className='d-inline mx-2'
                        disabled={updating}
                        onClick={() => {
                            isCompound ?
                                onUpdated(values, crossingType, "YES")
                                :
                                onUpdated(values, data, certificateId, crossingType, "YES")
                        }}
                    >
                        {updating ? "Please wait..." : "Update Certificate"}
                    </Button>
                }

                {/* Admin Dropdown */}
                <Dropdown className='d-inline'>
                    <Dropdown.Toggle as={Button} variant="light" size="sm" className="text-dark">
                        <FontAwesomeIcon icon={faCog} className=" me-2" />
                        {isAdmin ? "Admin" : ""}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-left mt-2">
                        <PermissionCheck errorProps={{ disabled: true }} requiresRole={['admin']}>
                            <Dropdown.Item onClick={() => setShowModalViewData(true)} >
                                View Data
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setShowModalViewConfig(true)} >
                                View Config
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setShowModalAdd(true)}>
                                {isSubmitting ? "Please wait..." : "Add Group"}
                            </Dropdown.Item>
                        </PermissionCheck>

                        <Dropdown.Item onClick={() => setShowSettingsModal(true)} >
                            Change Crossing Type
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>


                {/* Admin List Modals */}
                <ViewDataModal
                    showModalViewData={showModalViewData}
                    handleCloseModalViewData={handleCloseModalViewData}
                    data={initialSavedData}
                />
                <ViewConfigModal
                    showModalViewConfig={showModalViewConfig}
                    handleCloseModalViewConfig={handleCloseModalViewConfig}
                    data={config}
                />
                <AddGroupModal
                    values={values}
                    totalPages={numPages}
                    data={data}
                    setData={setData}
                    showModalAdd={showModalAdd}
                    handleCloseModalAdd={handleCloseModalAdd}
                    handleDataObjectUpdate={handleDataObjectUpdate}
                    certificateGroups={certificateGroups}
                />

                {/* CrossingTypeModal */}
                <CrossingTypeModal
                    show={showSettingsModal}
                    handleClose={handleCloseSettingsModal}
                    selectedCrossingType={selectedCrossingType}
                    setSelectedCrossingType={setSelectedCrossingType}
                    handleConfirm={handleConfirmSettingsModal}
                />

            </Col>
        </Row>
    )

}

export default NavigationBar;